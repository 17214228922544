.DisplayShares-heading {
  margin-top: 0;
}

.DisplayShares-list li {
  padding-top: 0.5em;
}

.DisplayShares-share {
  color: #ffffff;
}
