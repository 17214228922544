.App {
  max-width: 960px;
  margin: 0 auto;
  font-size: 24px;
  padding: 1em;
}

.content {
  padding-top: 2em;
}

@media (max-width: 500px) {
  .App {
    font-size: 18px;
  }

  .content {
    padding-top: 0.5em;
  }
}
