.DecodeForm-header {
  margin-top: 0;
}

.DecodeForm {
  display: grid;
  grid-row-gap: 1em;
  grid-template-columns: 1fr 3fr;
}

.DecodeForm-count {
  width: 4em;
}

.DecodeForm label {
  justify-self: right;
  padding: 4px 0.5em 4px 4px;
}

.DecodeForm-submit {
  grid-column: 2/2;
  width: 8em;
  background-color: #2cbc9d;
  color: #ffffff;
  border: 0;
  padding: 0.5em 2em;
  border-radius: 0.5em;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.DecodeForm-error {
  color: #dc3545;
  font-size: 0.8em;
}

@media (max-width: 500px) {
  .DecodeForm-header {
    font-weight: 400;
  }
}
