.CreateForm {
  display: grid;
  grid-row-gap: 1em;
}

.CreateForm-field {
  display: flex;
  flex-direction: column;
}

.CreateForm-field label {
  padding-bottom: 0.25em;
}

.CreateForm-secret {
  width: 100%;
}

.CreateForm-count {
  width: 4em;
}

.CreateForm-submit {
  min-width: 6em;
  background-color: #2cbc9d;
  color: #ffffff;
  border: 0;
  padding: 0.5em 2em;
  border-radius: 0.5em;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.CreateForm-error {
  color: #dc3545;
  font-size: 0.8em;
}
