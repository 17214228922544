.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
}

.Header h1 {
  font-weight: 200;
  font-size: 3em;
  margin: 0;
}

.Header h1 strong {
  font-weight: 700;
}

.Header-nav {
  display: flex;
  padding: 0;
  font-size: 1.25em;
}

.Header-nav li::before {
  content: "|";
}

.Header-nav li:first-child::before {
  content: "";
}

.Header-navlink {
  list-style-type: none;
}

.Header-navlink a {
  padding: 0 0.5em;
  color: #ffffff;
  text-decoration: none;
  font-weight: 200;
}

.Header-navlink a:hover {
  color: #2cbc9d;
}

.Header-navlink a.active {
  font-weight: bold;
}

.Header-navlink a:hover {
  color: #ffffff;
}

@media (max-width: 500px) {
  .Header {
    flex-direction: column;
  }

  .Header-nav {
    margin: 0;
    padding: 1em;
  }
}
